import { AxiosResponse } from 'axios'
import { cdcServiceAPI } from '@/store/api-instance'
import { ISetUserVerificationRequest } from '@/store/modules/auth/interfaces/setUserVerification/ISetUserVerificationRequest'
import { ISetFirstLogin } from '@/store/modules/auth/interfaces/setFirstLogin/ISetFirstLogin'

async function setUserVerificationAPI (params: ISetUserVerificationRequest) {
  const response: AxiosResponse = await cdcServiceAPI.put<boolean>('users/SetFirstPassword', params)
  return response.data
}

async function setFirstLogin (params: ISetFirstLogin) {
  const response: AxiosResponse = await cdcServiceAPI.request<boolean>({
    method: 'PUT',
    url: `users/SetFirstLoginFlag/${params.UID}`,
    headers: {
      'content-type': 'application/json'
    },
    data: params.firstLogin
  })
  return response.data
}

async function getUserInformationAPI (UID: string) {
  const response: AxiosResponse = await cdcServiceAPI.get(`users/GetUserInfoById/${UID}`)
  localStorage.setItem('isRecommendedArray', JSON.stringify(response.data.permissionSchema))
  return response.data
}

export { setUserVerificationAPI, getUserInformationAPI, setFirstLogin }
