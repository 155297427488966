import {
  createRouter as createAsahiRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  NavigationGuardNext
} from 'vue-router'
import { Store } from 'vuex'
import { IRootState } from '@/store'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { constants } from '@/constants'
import { nextTick } from 'vue'
import { getValueByLabel } from '@/utils/permissions'
import DefaultLayout from '@/components/layout/DefaultLayout.vue'
import HomeLayout from '@/components/layout/HomeLayout.vue'

const history = createWebHistory()

export type AsahiRouter = Router & { navigate: (url: string | CustomEvent) => void }

async function checkSession (store: Store<IRootState>, next: NavigationGuardNext) {
  // Ensure Gigya is fully initialized before checking the session
  await new Promise<void>((resolve) => {
    if (!window.gigya || typeof window.gigya.init !== 'function') {
      // Polling to check if Gigya is initialized
      const checkGigyaInterval = setInterval(() => {
        if (window.gigya && typeof window.gigya.init === 'function') {
          clearInterval(checkGigyaInterval)
          resolve()
        }
      }, 100)

      // Timeout if Gigya is not initialized within a reasonable time
      setTimeout(() => {
        clearInterval(checkGigyaInterval)
        console.error('Gigya is not initialized within the expected time frame.')
        resolve() // Proceed even if initialization fails
      }, 100)
    } else {
      resolve()
    }
  })

  // Now you can safely call window.gigya.hasSession() and other methods
  if (window.gigya && typeof window.gigya.hasSession === 'function') {
    window.gigya.hasSession().then(
      window.gigya.socialize.getUserInfo({
        callback: (res: any) => {
          if (res.UID != null) {
            sessionStorage.setItem('getUserInfoResponse', JSON.stringify(res))
            store.commit('SET_AUTH_USER', res)
            store.dispatch(AuthActionTypes.GET_USER_INFO, { id: res.UID })
          } else {
            store.dispatch('logout')
            next({ name: 'login' })
          }
        }
      })
    )
  } else {
    console.error('Gigya is not properly initialized.')
    const uid = sessionStorage.getItem('uid')
    if (uid !== null) {
      const getUserInfoResponse = JSON.parse(sessionStorage.getItem('getUserInfoResponse') as string || '') || null
      if (getUserInfoResponse) {
        store.commit('SET_AUTH_USER', getUserInfoResponse)
        store.dispatch(AuthActionTypes.GET_USER_INFO, { id: uid })
      } else {
        store.dispatch('logout')
        next({ name: 'login' })
      }
    } else {
      // store.dispatch('logout')
      const path = window.history.state.current
      localStorage.setItem('redirectpath', path)
      next({ name: 'login' })
    }
    // Handle the error or redirect as needed
  }
}
/*
async function checkSession (store: Store<IRootState>, next: NavigationGuardNext) {
  // Ensure Gigya is fully initialized before checking the session
  await new Promise<void>((resolve) => {
    if (!window.gigya || typeof window.gigya.init !== 'function') {
      // Simulated initialization delay for demonstration purposes
      setTimeout(() => {
        resolve()
      }, 1000)
    } else {
      resolve()
    }
  })

  // Now you can safely call window.gigya.hasSession() and other methods
  if (window.gigya && typeof window.gigya.hasSession === 'function') {
    window.gigya.hasSession().then(
      window.gigya.socialize.getUserInfo({
        callback: (res: any) => {
          if (res.UID != null) {
            store.commit('SET_AUTH_USER', res)
            store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID)
          } else {
            store.dispatch('logout')
            next({ name: 'login' })
          }
        }
      })
    )
  } else {
    console.error('Gigya is not properly initialized.')
    // Handle the error or redirect as needed
  }
}
*/

function checkPermission (store: Store<IRootState>, next: NavigationGuardNext, key: string) {
  function proceed () {
    const permissionData = store.state?.main?.permissions
    if (store.getters.getIsFullLoaded() === true) {
      if (store.state.auth.user.allAvailablePermission.includes(key) && getValueByLabel(permissionData, key)) {
        next()
      } else {
        next({ name: 'Error403' })
      }
    }
  }

  if (store.getters.getIsFullLoaded() !== true || !store.state?.main?.permissions.length) {
    if (store.getters.getIsFullLoaded() !== true) {
      // Handle asynchronous initialization here
      checkSession(store, next)

      store.watch(store.getters.getIsFullLoaded, function () {
        if (store.getters.getIsFullLoaded() === true && store.state?.main?.permissions.length) {
          proceed()
        }
      })
    }

    if (!store.state?.main?.permissions.length) {
      store.dispatch('main/FETCH_PERMISSIONS', { root: true })

      store.watch(store.getters['main/getPermissions'], function () {
        if (store.getters.getIsFullLoaded() === true && store.state?.main?.permissions.length) {
          proceed()
        }
      })
    }
  } else {
    proceed()
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createRoutes (store: Store<IRootState>): RouteRecordRaw[] {
  return [
    {
      path: '/',
      name: 'homepage',
      meta: {
        requireAuthentication: constants.application.theme === 'prazdroj',
        title: 'Prazdroj Portál',
        // layout: HomeLayout
        layout: DefaultLayout
      },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(store, next, 'Home Page')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "homepage" */ '../pages/HomePage.vue')
    },
    {
      path: '/verification',
      name: 'userVerification',
      meta: { isUserVerification: true, title: 'User verification' },
      component: () => import(/* webpackChunkName: "verification" */ '../pages/UserVerificationPage.vue')
    },
    {
      path: '/catalog/products',
      name: 'products',
      meta: {
        isProducts: 'product',
        requireAuthentication: true,
        title: 'Products catalogue',
        layout: DefaultLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        hideCreateOrderButton: true
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "products" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/:id',
      name: 'productsDetail',
      meta: {
        requireAuthentication: true,
        title: 'Product details',
        layout: DefaultLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        hideCreateOrderButton: true,
        showBackToCatalog: true
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "productsDetail" */ '../components/products/ProductDescription.vue')
    },
    {
      path: '/catalog/favorites',
      name: 'favorites',
      meta: {
        isProducts: 'favorites',
        requireAuthentication: true,
        title: 'My favorites',
        layout: DefaultLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },

      beforeEnter: (to, from, next) => checkPermission(store, next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "favorites" */ '../components/products/FavoritesPage.vue')
    },
    {
      path: '/catalog/suggested-cart',
      name: 'suggested-cart',
      meta: {
        isProducts: 'fastOrder',
        requireAuthentication: true,
        title: 'Suggested Cart',
        layout: DefaultLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Product Recommendation'),
      component: () => import(/* webpackChunkName: "products" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/orders/history',
      name: 'orders-history',
      meta: {
        tab: 'history',
        requireAuthentication: true,
        title: 'Orders history',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'My Orders'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/orders/saved',
      name: 'saved-orders',
      meta: {
        requireAuthentication: true,
        title: 'Saved Orders',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Catalogue'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/my-profile/:tab',
      name: 'my-profile',
      meta: {
        requireAuthentication: true,
        title: 'My profile',
        layout: DefaultLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'My Profile'),
      component: () => import(/* webpackChunkName: "profile" */ '../pages/MyAccount.vue')
    },
    {
      path: '/contracts',
      name: 'contracts',
      meta: {
        requireAuthentication: true,
        title: 'Contracts',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Contracts'),
      component: () => import('../pages/MyAccount.vue')
    },
    {
      path: '/reporting',
      name: 'reporting',
      meta: {
        requireAuthentication: true,
        title: 'Reporting',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Reporting'),
      component: () => import('../pages/MyAccount.vue')
    },
    {
      path: '/my-documents',
      name: 'myDocuments',
      meta: {
        tab: 'my-document',
        requireAuthentication: true,
        title: 'My documents',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        filterTypeIsSelector: true
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'My Documents'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/MyAccount.vue')
    },
    {
      path: '/marketing-materials',
      name: 'marketingMaterials',
      meta: {
        requireAuthentication: true,
        title: 'Marketing materials',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        tabSelection: 'marketing'
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Marketing Materials'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/FilesPage.vue')
    },
    {
      path: '/news',
      name: 'news',
      meta: {
        requireAuthentication: constants.application.theme === 'prazdroj',
        title: 'News',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        tabSelection: 'news'
      },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(store, next, 'News & Articles')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "news" */ '../pages/FilesPage.vue')
    },
    {
      path: '/training',
      name: 'training',
      meta: { requireAuthentication: true, title: 'Training & learning', layout: HomeLayout },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Training & Learning'),
      component: () => import(/* webpackChunkName: "news" */ '../pages/TrainingLearningPage.vue')
    },
    {
      path: '/news/:path',
      name: 'newsItem',
      meta: {
        requireAuthentication: constants.application.theme === 'prazdroj',
        title: 'News item',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }],
        tabSelection: 'news'
      },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(store, next, 'News & Articles')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "news" */ '../pages/ArticlePage.vue')
    },
    {
      path: '/support/:path',
      name: 'support',
      meta: {
        requireAuthentication: true,
        title: 'Support',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Support'),
      component: () => import(/* webpackChunkName: "favorites" */ '../components/support/AsahiSupportPage.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { noPortalChrome: true, title: 'Login' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "login" */ '../pages/LoginPage.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      meta: {
        title: 'Forgot password',
        breadCrumb: [{ text: '', path: '/' }]
      },
      component: () => import(/* webpackChunkName: "forgot password" */ '../pages/ForgotPasswordPage.vue')
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      meta: { title: 'Reset password' },
      component: () => import(/* webpackChunkName: "reset password" */ '../pages/ResetPasswordPage.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      meta: {
        hideFooter: true,
        requireAuthentication: true,
        title: 'My cart',
        layout: HomeLayout,
        breadCrumb: [{ text: 'Home', path: '/' }]
      },
      beforeEnter: (to, from, next) => checkPermission(store, next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "cart" */ '../pages/CartCheckoutPage.vue')
    },
    {
      path: '/thanks',
      name: 'thanks',
      meta: { requireAuthentication: true, title: 'Thanks', layout: HomeLayout },
      component: () => import(/* webpackChunkName: "thanks" */ '../pages/ThanksPage.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { title: 'Contact', layout: HomeLayout },
      component: () => import(/* webpackChunkName: "contact" */ '../pages/ContactPage.vue')
    },
    {
      path: '/saml-error',
      name: 'saml-error',
      meta: { noPortalChrome: true, title: 'SAML error' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "files" */ '../pages/ErrorSAMLPage.vue')
    },
    {
      path: '/error403',
      name: 'Error403',
      meta: { title: 'Error' },
      component: () => import(/* webpackChunkName: "error403" */ '../pages/Error403Page.vue')
    },

    // this MUST be the last route
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      meta: { title: 'Error404' },
      component: () => import(/* webpackChunkName: "error404" */ '../pages/Error404Page.vue')
    }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createRouter (store: Store<IRootState>): AsahiRouter {
  const routes = createRoutes(store)

  const router = createAsahiRouter({
    routes,
    history,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition && from.name !== 'newsItem') {
        return savedPosition
      }

      if (to.query.c && from.params.productUrl === to.params.productUrl) {
        return {}
      }

      if (
        to.name === 'products' &&
        from.name === 'products' &&
        to.path === from.path &&
        to.query.page === from.query.page
      ) {
        return {}
      }

      if (to.hash) {
        return { el: to.hash }
      }

      return { top: 0 }
    }
  }) as AsahiRouter

  // https://next.router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next
  router.beforeEach(async (to) => {
    const DEFAULT_TITLE = constants.application.pageTitle
    nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
    })
    store.commit('main/SET_ROUTE', to)
    // disable data fetching for ssr-rendered 404 pages
    if (store.state.main.ssrRendered && store.state.main.pageNotFound) {
      store.commit('main/SET_SSR_RENDERED', false)
    }
    store.commit('main/SET_PAGE_NOT_FOUND', true)

    return true
  })

  router.afterEach(async (to, from) => {
    store.commit('main/SET_ROUTE_LOADING', false)
    store.commit('main/SET_HISTORY', from)
    return true
  })

  router.navigate = (url: string | CustomEvent) => {
    const target =
      typeof url === 'string'
        ? new URL(url, window.location.toString())
        : new URL(url.detail?.href ? url.detail.href : url.detail, window.location.toString())
    if (
      target.origin === window.location.origin &&
      target.pathname === window.location.pathname &&
      target.search === window.location.search
    ) {
      if (target.hash === '') return
      const element = document.querySelector(target.hash) as HTMLElement
      window.scrollBy({
        top: element ? element.getBoundingClientRect().top : 0,
        left: 0,
        behavior: 'smooth'
      })
    } else if (target.origin === window.location.origin) {
      router.push(target.pathname + target.search + target.hash)
    } else {
      window.location.assign(target.href)
    }
  }

  return router
}
