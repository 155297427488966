import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IBasketState } from '@/store/modules/basket/interfaces/IBasketState'
import { IBasketAction } from '@/store/modules/basket/interfaces/IBasketAction'
import { IBasketMutation } from '@/store/modules/basket/interfaces/IBasketMutation'
import { BasketMutationTypes } from '@/store/modules/basket/interfaces/mutation-types'
import { IFetchBasketRequest } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketRequest'
import { BasketActionTypes } from '@/store/modules/basket/interfaces/action-types'
import { apolloClient } from '@/store/graphql.config'
import { IFetchBasketResponse } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketResponse'
import { IBasketProduct } from '@/store/modules/basket/interfaces/IBasketProduct'
import { fetchCustomerBasketQuery } from './queries/fetchCustomerBasket.query'
import { fetchProductsToCartMutation } from './mutations/fetchProductsToCart.mutation'
import { IAddToBasketResponse } from './interfaces/addProductToBasket/IAddToBasketResponse'
import { IAddMultiToBasketRequest, IAddToBasketRequest } from './interfaces/addProductToBasket/IAddToBasketRequest'
import { IRemoveFromBasketResponse } from './interfaces/removeFromBasket/IRemoveFromBasketResponse'
import { IRemoveFromBasketRequest } from './interfaces/removeFromBasket/IRemoveFromBasketRequest'
import { fetchRemoveCardItemMutation } from './mutations/fetchRemoveCardItem.mutation'
import { fetchUpdateCartItemsMutation } from './mutations/fetchUpdateCartItems.mutation'
import { fetchUpdatePricesMutation } from './mutations/fetchUpdatePrices.mutation'
import { IUpdateItemsRequest } from './interfaces/updateItems/IUpdateItemsRequest'
import { IUpdateItemsResponse } from './interfaces/updateItems/IUpdateItemsResponse'
import { IUpdatePricesRequest } from './interfaces/updatePrices/IUpdatePricesRequest'
import { Empties, IUpdatePricesResponse } from './interfaces/updatePrices/IUpdatePricesResponse'
import { fetchPlaceOrderMutation } from './mutations/fetchPlaceOrder.mutation'
import { IPlaceOrderRequest } from './interfaces/placeOrder/IPlaceOrderRequest'
import { IPlaceOrderResponse } from './interfaces/placeOrder/IPlaceOrderResponse'
import { fetchShippingAddressMutation } from './mutations/fetchShippingAddress.mutation'
import { ISetShippingAddressRequest } from './interfaces/setShippingAddress/ISetShippingAddressRequest'
import {
  ISetShippingAddressResponse,
  ShippingAddress
} from './interfaces/setShippingAddress/ISetShippingAddressResponse'
import { fetchShippingMethodMutation } from './mutations/fetchShippingMethod.mutation'
import { ISetShippingMethodRequest } from './interfaces/setShippingMethod/ISetShippingMethodRequest'
import { ISetShippingMethodResponse, ShippingMethod } from './interfaces/setShippingMethod/ISetShippingMethodResponse'
import { fetchCreateBasketMutation } from './mutations/fetchCreateBasket.mutation'
import { ISetEmptyCartResponse } from './interfaces/emptyCart/ISetEmptyCartResponse'
import { fetchCartQuery } from './queries/fetchCartQuery.query'
import { ICartQueryRequest } from './interfaces/cartQuery/ICartQueryRequest'
import {
  BaseCartQueryItem,
  BaseCartQueryResponse,
  ICartQueryResponse,
  LastCustomerSettings
} from './interfaces/cartQuery/ICartQueryResponse'
import { IError } from '@/store/modules/catalog/interfaces/common/IError'
import { fetchTransportTypeMutation } from './mutations/fetchTransportType.mutation'
import { ISetTransportTypeRequest } from './interfaces/fetchTransportType/ISetTransportTypeRequest'
import { ISetTransportTypeResponse } from './interfaces/fetchTransportType/ISetTransportTypeResponse'
import { addSaveOrderForLaterQuery } from './queries/addSaveOrderForLater.query'
import { createSuggestedCartQuery } from './queries/createSuggestedCart.query'
import { fetchSuggestedCartQuery } from './queries/fetchSuggestedCart.query'
import { IAddSaveOrderForLaterRequest, IAddSaveOrderForLaterResponse } from './interfaces/addSaveOrderForLater'
import { fetchMinCartForCatalogQuery } from './queries/fetchMinCartForCatalogQuery.query'
import { fetchHPCartQuery } from './queries/fetchHPCartQuery.query'
import { fetchCartValuesQuery } from './queries/fetchCartValuesQuery.query'
import i18n from '@/i18n'
import { ICartQueryGrandTotalYourPriceDetails } from './interfaces/cartQuery/ICartQueryItemsPricesDetails'
import { fetchCustomerLastSettingsQuery } from './queries/fetchCustomerLastSettings.queries'
import { IFetchCustomerLastSettingsRequest } from './interfaces/fetchCustomerSettings/IFetchCustomerRequest'
import { IFetchCustomerLastSettingsResponse } from './interfaces/fetchCustomerSettings/IFetchCustomerResponse'
import {
  ICreateSuggestedCartRequest,
  ICreateSuggestedCartResponse,
  IFetchSuggestedCartRequest,
  IFetchSuggestedCartResponse
} from './interfaces/suggestedCart'
import { cloneDeep } from 'lodash'
import { TransportationTypes } from '@/utils/transportTypes'
import { updatePromoValidity } from '@/utils/price'
import { fetchMultiProductsToCartMutation } from '@/store/modules/basket/mutations/fetchMultiProductsToCart.mutation'
import { isExceptionDeliveryDate } from '@/utils/deliveryDate'

const state = (): IBasketState => ({
  id: '',
  emptyCartId: '',
  suggestedBaskets: [],
  suggestedCarts: [],
  items: [],
  loading: false,
  error: null,
  query: {} as BaseCartQueryResponse,
  shippingAddress: [],
  shippingMethods: [],
  prices: {} as IUpdatePricesResponse,
  order: {} as IPlaceOrderResponse,
  availableDate: '',
  deliveryDate: '',
  shipmentId: '',
  transportType: '',
  progressBarMsg: '',
  customerSettings: {} as LastCustomerSettings,
  suggestedCart: {},
  empties: [],
  updatedEmpties: [],
  defaultEmpties: [],
  separatedOrder: [],
  showToastDetails: { show: false, productName: '', actionType: '' }
})

const getters = {
  progressBarMessage: (state: IBasketState) => {
    const progressBar = state.query?.progress_bar_data
    const emptyTypes = ['emptiespickup_emptiespickup', 'emptiesfulltruck_emptiesfulltruck']

    if (progressBar && state.query) {
      if (emptyTypes.includes(state.query?.delivery_method)) {
        if (progressBar?.iTotalCartPalletQty < progressBar?.iMinimumOrderPallet) {
          return i18n.global.t('The minimum quantity of pallet has not been reached')
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          return i18n.global.t('The maximum number of pallets has been exceeded.')
        }
      } else if (
        state.query?.delivery_method === 'flatrate_flatrate' ||
        state.query?.delivery_method === 'emptiesfulltruck_emptiesfulltruck'
      ) {
        if (
          progressBar?.iTotalCartPalletQty < progressBar?.iMinimumOrderPallet &&
          progressBar?.iTotalCartWeight < progressBar?.iMinimumOrderWeight
        ) {
          return i18n.global.t('The minimum quantity of pallet has not been reached')
        }
        if (progressBar?.iTotalCartWeight > progressBar?.iMaximumOrderWeight) {
          return i18n.global.t('The maximum weight of order has been exceeded.')
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          return i18n.global.t('The maximum number of pallets has been exceeded.')
        }
        if (
          progressBar?.iTotalCartPalletQty >= progressBar?.iMinimumOrderPallet &&
          progressBar?.iTotalCartPalletQty < progressBar?.iMaximumOrderPallet &&
          progressBar?.iTotalCartWeight < progressBar?.iMinimumOrderWeight
        ) {
          return i18n.global.t('The minimum weight of order has not been reached')
        }
        if (
          progressBar?.iTotalCartPalletQty === progressBar?.iMaximumOrderPallet &&
          progressBar?.iTotalCartWeight <= progressBar?.iMaximumOrderWeight
        ) {
          return i18n.global.t('The minimum order capacity has been reached.')
        }
      } else {
        if (
          Number(
            (state.query?.grand_total_your_price_details as ICartQueryGrandTotalYourPriceDetails)
              ?.grand_total_your_price
          ) < Number(progressBar?.iMaximumOrderCash)
        ) {
          return i18n.global.t('The minimum price of order has not yet been reached')
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          return i18n.global.t('The maximum number of pallets has been exceeded.')
        }
        if (
          Number(
            (state.query?.grand_total_your_price_details as ICartQueryGrandTotalYourPriceDetails)
              ?.grand_total_your_price
          ) >= Number(progressBar?.iMaximumOrderCash)
        ) {
          return i18n.global.t('The minimum order capacity has been reached.')
        }
      }
    }
    return ''
  },
  progressBarMessageClass: (state: IBasketState) => {
    const progressBar = state.query?.progress_bar_data
    const emptyTypes = ['emptiespickup_emptiespickup', 'emptiesfulltruck_emptiesfulltruck']

    if (progressBar && state.query) {
      if (emptyTypes.includes(state.query?.delivery_method)) {
        if (progressBar?.iTotalCartPalletQty < progressBar?.iMinimumOrderPallet) {
          // Message: 'The minimum quantity of pallet has not been reached'
          return ''
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          // Message: 'The maximum number of pallets has been exceeded.'
          return 'secondary-red'
        }
      } else if (
        state.query?.delivery_method === 'flatrate_flatrate' ||
        state.query?.delivery_method === 'emptiesfulltruck_emptiesfulltruck'
      ) {
        if (
          progressBar?.iTotalCartPalletQty < progressBar?.iMinimumOrderPallet &&
          progressBar?.iTotalCartWeight < progressBar?.iMinimumOrderWeight
        ) {
          // Message: 'The minimum quantity of pallet has not been reached'
          return ''
        }
        if (progressBar?.iTotalCartWeight > progressBar?.iMaximumOrderWeight) {
          // message: 'The maximum weight of order has been exceeded.'
          return 'secondary-red'
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          // Message: 'The maximum number of pallets has been exceeded.'
          return 'secondary-red'
        }
        if (
          progressBar?.iTotalCartPalletQty >= progressBar?.iMinimumOrderPallet &&
          progressBar?.iTotalCartPalletQty < progressBar?.iMaximumOrderPallet &&
          progressBar?.iTotalCartWeight < progressBar?.iMinimumOrderWeight
        ) {
          // Message: 'The minimum weight of order has not been reached'
          return ''
        }
        if (
          progressBar?.iTotalCartPalletQty === progressBar?.iMaximumOrderPallet &&
          progressBar?.iTotalCartWeight <= progressBar?.iMaximumOrderWeight
        ) {
          // Message: 'The minimum order capacity has been reached.'
          return 'secondary-green'
        }
      } else {
        if (
          Number(
            (state.query?.grand_total_your_price_details as ICartQueryGrandTotalYourPriceDetails)
              ?.grand_total_your_price
          ) < Number(progressBar?.iMaximumOrderCash)
        ) {
          // Message: 'The minimum price of order has not yet been reached'
          return ''
        }
        if (progressBar?.iTotalCartPalletQty > progressBar?.iMaximumOrderPallet) {
          // Message: 'The maximum number of pallets has been exceeded.'
          return 'secondary-red'
        }
        if (
          Number(
            (state.query?.grand_total_your_price_details as ICartQueryGrandTotalYourPriceDetails)
              ?.grand_total_your_price
          ) >= Number(progressBar?.iMaximumOrderCash)
        ) {
          // Message: 'The minimum order capacity has been reached.'
          return 'secondary-green'
        }
      }
    }
    return ''
  },
  progressBarSuccess (state: IBasketState, getters: any) {
    return getters.progressBarMessage === i18n.global.t('The minimum order capacity has been reached.')
  },
  showProgressBarMessage (state: IBasketState) {
    return state.query?.delivery_method !== TransportationTypes.EMPTY_SMALL
  }
}

const mutations: MutationTree<IBasketState> & IBasketMutation = {
  [BasketMutationTypes.SET_EMPTY_CART] (state, payload: string): void {
    state.emptyCartId = payload
  },
  [BasketMutationTypes.SET_CART_QUERY] (state, payload: BaseCartQueryResponse): void {
    state.query = payload
  },
  [BasketMutationTypes.SET_BASKET_ID] (state, payload: string): void {
    state.id = payload
  },
  [BasketMutationTypes.ADD_SUGGESTED_BASKET] (state, payload: BaseCartQueryResponse): void {
    const idx = state.suggestedBaskets.findIndex((b) => b.ship_to_id === payload.ship_to_id)
    if (idx > -1) {
      state.suggestedBaskets[idx] = payload
    } else {
      state.suggestedBaskets.push(payload)
    }
  },
  [BasketMutationTypes.SET_SUGGESTED_BASKET] (state, payload: IFetchSuggestedCartResponse): void {
    state.suggestedCarts = payload.getSuggestedCarts?.items
  },
  [BasketMutationTypes.RESET_SUGGESTED_BASKETS] (state): void {
    state.suggestedBaskets = []
  },
  [BasketMutationTypes.SET_BASKET_ITEMS] (state, payload: IBasketProduct[]): void {
    state.items = payload
  },
  [BasketMutationTypes.SET_ERROR] (state, payload: unknown): void {
    state.error = payload
  },
  [BasketMutationTypes.SET_LOADING] (state, payload: boolean): void {
    state.loading = payload
  },
  [BasketMutationTypes.SET_SHIPPING_ADDRESS] (state, payload: ShippingAddress[]): void {
    state.shippingAddress = payload
  },
  [BasketMutationTypes.SET_SHIPPING_METHODS] (state, payload: ShippingMethod[]): void {
    state.shippingMethods = payload
  },
  [BasketMutationTypes.SET_PRICES] (state, payload: IUpdatePricesResponse): void {
    state.prices = payload
  },
  [BasketMutationTypes.SET_ORDER] (state, payload: IPlaceOrderResponse): void {
    state.order = payload
  },
  [BasketMutationTypes.SET_DELIVERY_DATE] (state, payload: string): void {
    state.deliveryDate = payload
  },
  [BasketMutationTypes.SET_SHIPMENT_ID] (state, payload: string): void {
    state.shipmentId = payload
  },
  [BasketMutationTypes.SET_TRANSPORT_TYPE] (state, payload: string): void {
    state.transportType = payload
  },
  [BasketMutationTypes.SET_PROGRESS_BAR] (state, payload: string): void {
    state.progressBarMsg = payload
  },
  [BasketMutationTypes.SET_CUSTOMER_SETTINGS] (state, payload: LastCustomerSettings): void {
    state.customerSettings = payload
  },
  [BasketMutationTypes.SET_EMPTIES] (state, payload: Empties[]): void {
    state.empties = payload
  },
  [BasketMutationTypes.SET_DEFAULT_EMPTIES] (state, payload: Empties[]): void {
    state.defaultEmpties = payload
  },
  [BasketMutationTypes.SET_UPDATED_EMPTIES] (state, payload: Empties[]): void {
    state.updatedEmpties = payload
  },
  [BasketMutationTypes.SET_SEPARATED_ORDER] (state, payload: Empties[]): void {
    state.separatedOrder = payload
  },
  [BasketMutationTypes.SET_TOAST_NOTIFICATION_DETAILS] (state, payload: any): void {
    state.showToastDetails = payload
  }
}

const actions: ActionTree<IBasketState, IRootState> & IBasketAction = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [BasketActionTypes.RESET_SUGGESTED_BASKETS] ({ commit }) {
    await commit(BasketMutationTypes.RESET_SUGGESTED_BASKETS)
  },

  async [BasketActionTypes.CREATE_EMPTY_CART] () {
    await apolloClient.mutate<ISetEmptyCartResponse, object>({
      mutation: fetchCreateBasketMutation
    })
  },
  async [BasketActionTypes.FETCH_CUSTOMER_LAST_SETTINGS] ({ commit }, dto: IFetchCustomerLastSettingsRequest) {
    const { data } = await apolloClient.mutate<IFetchCustomerLastSettingsResponse, IFetchCustomerLastSettingsRequest>({
      mutation: fetchCustomerLastSettingsQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (data) {
      commit(BasketMutationTypes.SET_CUSTOMER_SETTINGS, data?.cart)
    }
  },
  async [BasketActionTypes.FETCH_CART_QUERY] ({ commit, state, rootState }, dto: ICartQueryRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchCartQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })

    if (data) {
      data.cart = parseCartQuery(data.cart)
      if (data?.cart?.empties?.length > 0) {
        commit(BasketMutationTypes.SET_EMPTIES, data?.cart?.empties)
      }
    }
    if (data?.cart?.delivery_date) {
      localStorage.setItem('deliveryDate', data?.cart?.delivery_date)
      localStorage.setItem('exceptiondate',
        Number(isExceptionDeliveryDate(rootState.deliveryDates.calendarException,
          localStorage.getItem('shipToId') ?? '',
          data?.cart?.delivery_date, state.shipmentId as TransportationTypes)).toString())
    }
    commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
    commit(BasketMutationTypes.SET_LOADING, false)
  },
  async [BasketActionTypes.FETCH_MIN_CART_QUERY_FOR_CATALOG] ({ commit, state, rootState }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchMinCartForCatalogQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (data) {
      data.cart = parseCartQuery(data.cart)
    }
    if (data?.cart?.delivery_date) {
      localStorage.setItem('deliveryDate', data?.cart?.delivery_date)
      localStorage.setItem('exceptiondate',
        Number(isExceptionDeliveryDate(rootState.deliveryDates.calendarException,
          localStorage.getItem('shipToId') ?? '',
          data?.cart?.delivery_date, state.shipmentId as TransportationTypes)).toString())
    }
    commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
  },

  // fetch cart query on Homepage
  async [BasketActionTypes.FETCH_HP_CART_QUERY] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchHPCartQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (data) {
      data.cart.grand_total_your_price_details = JSON.parse(data?.cart.grand_total_your_price_details as string)
      commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
      commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
      commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
    }
  },

  async [BasketActionTypes.FETCH_CART_VALUES] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchCartValuesQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    commit(BasketMutationTypes.SET_CART_QUERY, {
      delivery_date: data?.cart.delivery_date,
      delivery_method: data?.cart?.delivery_method,
      ship_to_id: data?.cart?.ship_to_id,
      grand_total_your_price_details: JSON.parse(data?.cart?.grand_total_your_price_details as string),
      progress_bar_data: data?.cart?.progress_bar_data,
      outlet_details: data?.cart.outlet_details
    })
  },
  async [BasketActionTypes.FETCH_BASKET_ID] ({ commit }) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchBasketResponse, IFetchBasketRequest>({
      query: fetchCustomerBasketQuery
    })
    try {
      if (data) {
        commit(BasketMutationTypes.SET_BASKET_ID, data?.customerCart.id)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('fetch basket error', error)
    }
  },
  async [BasketActionTypes.FETCH_ADD_TO_BASKET] ({ commit }, dto: IAddToBasketRequest) {
    try {
      localStorage.setItem('isFirstAddToCart', 'false')

      if (!dto?.bypassloading) {
        commit(BasketMutationTypes.SET_LOADING, true)
      }
      await apolloClient.mutate<IAddToBasketResponse, IAddToBasketRequest>({
        mutation: fetchProductsToCartMutation,
        variables: {
          cart_id: dto.cart_id,
          sku: dto.sku,
          quantity: dto.quantity,
          number_of_layer: dto.number_of_layer,
          number_of_pallet: dto.number_of_pallet,
          selected_options: dto.selected_options
        }
      })
      commit(BasketMutationTypes.SET_LOADING, false)
      if (dto.productName !== '') {
        commit(BasketMutationTypes.SET_TOAST_NOTIFICATION_DETAILS, {
          show: true,
          productName: dto.productName,
          actionType: 'add'
        })
      }
    } catch (error: any) {
      console.error('add to cart error:', error)
      commit(BasketMutationTypes.SET_LOADING, false)
      throw new Error(error.message)
    }
  },
  async [BasketActionTypes.FETCH_ADD_MULTI_TO_BASKET] ({ commit }, dto: IAddMultiToBasketRequest) {
    try {
      localStorage.setItem('isFirstAddToCart', 'false')

      await apolloClient.mutate<IAddToBasketResponse, IAddMultiToBasketRequest>({
        mutation: fetchMultiProductsToCartMutation,
        variables: dto
      })
    } catch (error: any) {
      console.error('Add multiple to cart error:', error)
      throw new Error(error.message)
    } finally {
      commit(BasketMutationTypes.SET_LOADING, false)
    }
  },
  async [BasketActionTypes.REMOVE_ITEM] ({ commit, state, rootState }, dto: IRemoveFromBasketRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.mutate<IRemoveFromBasketResponse, IRemoveFromBasketRequest>({
      mutation: fetchRemoveCardItemMutation,
      variables: {
        cart_id: dto.cart_id,
        cart_item_id: dto.cart_item_id
      }
    })
    let cartData = data?.removeItemFromCart?.cart
    if (cartData) {
      cartData = parseCartQuery(cartData)
      if (cartData?.empties?.length && cartData?.empties?.length > 0) {
        commit(BasketMutationTypes.SET_EMPTIES, cartData?.empties)
      }
    }
    if (cartData?.delivery_date) {
      localStorage.setItem('deliveryDate', cartData?.delivery_date)
      localStorage.setItem('exceptiondate',
        Number(isExceptionDeliveryDate(rootState.deliveryDates.calendarException,
          localStorage.getItem('shipToId') ?? '',
          cartData?.delivery_date, state.shipmentId as TransportationTypes)).toString())
    }
    commit(BasketMutationTypes.SET_CART_QUERY, cartData)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, cartData?.delivery_date || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, cartData?.items)
    commit(BasketMutationTypes.SET_LOADING, false)
  },
  async [BasketActionTypes.UPDATE_ITEMS] ({ commit, state }, dto: IUpdateItemsRequest) {
    try {
      if (!dto?.bypassloading) {
        commit(BasketMutationTypes.SET_LOADING, true)
      }

      const { data } = await apolloClient.mutate<IUpdateItemsResponse, IUpdateItemsRequest>({
        mutation: fetchUpdateCartItemsMutation,
        variables: {
          cart_id: dto.cart_id,
          cart_items: dto.cart_items
        }
      })
      if (dto.productName !== '') {
        commit(BasketMutationTypes.SET_TOAST_NOTIFICATION_DETAILS, {
          show: true,
          productName: dto.productName,
          actionType: 'update'
        })
      }
      if (data) {
        const updated = [] as any
        data.updateCartItems.cart = parseCartQuery(data?.updateCartItems.cart)
        for (let i = 0; i < state.items?.length; i += 1) {
          for (let j = 0; j < data?.updateCartItems?.cart?.items?.length; j += 1) {
            if (state.items[i].id === data.updateCartItems.cart.items[j].id) {
              updated.push({
                id: state.items[i].id,
                product: state.items[i].product,
                number_of_layer: data.updateCartItems.cart.items[j].number_of_layer,
                number_of_layer_total: data.updateCartItems.cart.items[j].number_of_layer_total,
                number_of_packages: data.updateCartItems.cart.items[j].number_of_packages,
                number_of_pallet: data.updateCartItems.cart.items[j].number_of_pallet,
                quantity: data.updateCartItems.cart.items[j].quantity,
                quantity_total: data.updateCartItems.cart.items[j].quantity_total,
                prices: {
                  ...data.updateCartItems.cart.items[j].prices,
                  favourites: state.items[i].prices.favourites
                }
              })
            }
          }
        }
        commit(BasketMutationTypes.SET_BASKET_ITEMS, updated)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update items error:', error)
    }
  },
  async [BasketActionTypes.UPDATE_PRICES] ({ commit, rootGetters }, dto: IUpdatePricesRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.mutate<IUpdatePricesResponse, IUpdatePricesRequest>({
        mutation: fetchUpdatePricesMutation,
        variables: {
          cart_id: dto.cart_id,
          delivery_date: dto.delivery_date,
          date_popup: dto.date_popup,
          payment_method: dto.payment_method,
          outlet_order_type: dto.outlet_order_type,
          delivery_method: dto.delivery_method,
          flexDelivery: dto.flexDelivery,
          payment_method_title: dto.payment_method_title,
          note_for_driver: dto.note_for_driver,
          customer_order_number: dto.customer_order_number,
          empties_return: dto.empties_return,
          empties_data: dto.empties_data,
          no_empties_return: dto.no_empties_return ? dto.no_empties_return : '',
          use_integration: dto.use_integration
        }
      })
      if (data?.updatePrices) {
        commit(BasketMutationTypes.SET_PRICES, data?.updatePrices)
        if (data?.updatePrices?.empties_arr != null) {
          if (rootGetters['main/getRoute']?.name !== 'emptiesSummary') {
            let blgaProducts = [] as Empties[]
            const defaultEmpties = cloneDeep(data?.updatePrices?.empties_arr?.empties)?.map((item: Empties) => ({
              ...item,
              number_of_units: Number(item.number_of_units)
            }))
            if (data?.updatePrices?.cart_blga_arr?.length > 0) {
              blgaProducts = cloneDeep(data?.updatePrices?.cart_blga_arr)?.map((blga: Empties) => ({
                ...blga,
                number_of_units: Number(blga.number_of_units)
              }))
            }
            const result =
              blgaProducts?.length > 0
                ? defaultEmpties?.length > 0
                  ? blgaProducts.concat(defaultEmpties)
                  : blgaProducts
                : defaultEmpties
            commit(BasketMutationTypes.SET_DEFAULT_EMPTIES, result)
          }
          commit(BasketMutationTypes.SET_EMPTIES, data?.updatePrices?.empties_arr?.empties)
        }
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update prices error:', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_ADDRESS] ({ commit }, dto: ISetShippingAddressRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingAddressResponse, ISetShippingAddressRequest>({
        mutation: fetchShippingAddressMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_addresses: dto.shipping_addresses
        }
      })
      commit(BasketMutationTypes.SET_SHIPPING_ADDRESS, data?.setShippingAddressesOnCart)
    } catch (error) {
      console.error('shipping address error', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_METHODS] ({ commit }, dto: ISetShippingMethodRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingMethodResponse, ISetShippingMethodRequest>({
        mutation: fetchShippingMethodMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_methods: dto.shipping_methods
        }
      })
      const method = data?.setShippingMethodsOnCart?.cart.shipping_addresses as any
      if (typeof method !== 'undefined') {
        commit(BasketMutationTypes.SET_SHIPPING_METHODS, method[0]?.selected_shipping_method)
      }
    } catch (error) {
      console.error('shipping methods error', error)
    }
  },
  async [BasketActionTypes.FETCH_TRANSPORT_TYPE] ({ commit }, dto: ISetTransportTypeRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetTransportTypeResponse, ISetTransportTypeRequest>({
        mutation: fetchTransportTypeMutation,
        variables: {
          transporttype: dto.transporttype,
          cart_id: dto.cart_id,
          outletid: Number((localStorage.getItem('shipToId') as string) || 0),
          datepopup: localStorage.getItem('deliveryDate') as string,
          store: localStorage.getItem('store') as string
        }
      })
      if (data?.setTransportType?.message) {
        commit(BasketMutationTypes.SET_TRANSPORT_TYPE, dto.transporttype)
        localStorage.setItem('shippingMethod', dto.transporttype)
        const defaultNonEmptyTransportType = ['flatrate_flatrate', 'selfpickup_selfpickup']
        defaultNonEmptyTransportType.push('freeshipping_freeshipping')

        if (defaultNonEmptyTransportType.includes(dto.transporttype)) {
          localStorage.setItem('onlyEmpties', JSON.stringify(0))
        } else {
          localStorage.setItem('onlyEmpties', JSON.stringify(1))
        }
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('transport type error', error)
    }
  },
  async [BasketActionTypes.PLACE_ORDER] ({ commit }, dto: IPlaceOrderRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    try {
      const { data } = await apolloClient.mutate<IPlaceOrderResponse, IPlaceOrderRequest>({
        mutation: fetchPlaceOrderMutation,
        variables: {
          cart_id: dto.cart_id
        }
      })
      if (data) {
        commit(BasketMutationTypes.SET_ORDER, data?.placeOrder?.order)
        commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
        localStorage.removeItem('customerOrderNumber')
        localStorage.removeItem('noteForDriver')
      }
      commit(BasketMutationTypes.SET_ERROR, [])
    } catch (error: any) {
      commit(BasketMutationTypes.SET_ERROR, [error?.message])
      commit(BasketMutationTypes.SET_LOADING, false)
      console.error('place order error', error)
    }
  },
  async [BasketActionTypes.SAVE_ORDER_FOR_LATER] (
    { commit },
    { cartId, noteForDriver, customerOrderNumber }: IAddSaveOrderForLaterRequest
  ) {
    try {
      const { data } = await apolloClient.query<IAddSaveOrderForLaterResponse, IAddSaveOrderForLaterRequest>({
        query: addSaveOrderForLaterQuery,
        variables: {
          cartId,
          noteForDriver,
          customerOrderNumber
        }
      })
      commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.CREATE_SUGGESTED_CART] ({ commit }, dto: ICreateSuggestedCartRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.query<ICreateSuggestedCartResponse, ICreateSuggestedCartRequest>({
        query: createSuggestedCartQuery,
        variables: {
          shipTo: dto.shipTo,
          cartId: dto?.cartId
        }
      })

      if (data && data?.suggestedCart?.cartId) {
        const id: string = data.suggestedCart.cartId
        if (id) {
          const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
            mutation: fetchCartQuery,
            variables: {
              cart_id: id
            }
          })

          if (data) {
            data.cart = parseCartQuery(data.cart)
            commit(BasketMutationTypes.ADD_SUGGESTED_BASKET, data.cart)
          }
        }
      }
      commit(BasketMutationTypes.SET_LOADING, false)
    } catch (error) {
      console.error(error)
      commit(BasketMutationTypes.SET_ERROR, [error as IError])
      commit(BasketMutationTypes.SET_LOADING, false)
    }
  },
  async [BasketActionTypes.FETCH_SUGGESTED_CART] ({ commit }, dto: IFetchSuggestedCartRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.query<IFetchSuggestedCartResponse, IFetchSuggestedCartRequest>({
        query: fetchSuggestedCartQuery,
        variables: {
          ship_tos: dto.ship_tos
        }
      })

      if (data) {
        commit(BasketMutationTypes.SET_SUGGESTED_BASKET, data)
      }
      commit(BasketMutationTypes.SET_LOADING, false)
    } catch (error) {
      console.error(error)
      commit(BasketMutationTypes.SET_ERROR, [error as IError])
      commit(BasketMutationTypes.SET_LOADING, false)
    }
  }
}

const BasketModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function parseCartQuery (cart: BaseCartQueryResponse | any) {
  cart?.items?.map((item: BaseCartQueryItem) => {
    item.prices.price_details = JSON.parse(item.prices.price_details as unknown as string)
    item.prices.multi_deposit_details = JSON.parse(item.prices.multi_deposit_details as unknown as string)
    if (item.product?.bundle_price_details != null) {
      item.product.bundle_price_details = JSON.parse(item.product.bundle_price_details as string)
    }
    const updatedPrices = updatePromoValidity(item.product.promo_price_leaflet, item.prices.price_details.your_price, item.prices.price_details.your_price_tax)
    item.prices.price_details.your_price = updatedPrices.your_price
    item.prices.price_details.total_your_price = updatedPrices.your_price * item.quantity_total
    item.prices.price_details.your_price_tax = updatedPrices.your_price_vat
    item.prices.price_details.total_your_price_tax = updatedPrices.your_price_vat * item.quantity_total
    item.product.promo_price_leaflet = updatedPrices.promo_price_leaflet
    return item
  })
  cart.grand_total_your_price_details = JSON.parse(cart.grand_total_your_price_details as string)
  return cart
}

export default BasketModule
