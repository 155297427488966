import gql from 'graphql-tag'

export const fetchSuggestedCartQuery = gql(`
query ($ship_tos: [String]) {
  getSuggestedCarts(
    ship_tos: $ship_tos
  ) {
    items {
      summary_total_amount_wo_vat
      summary_total_amount_to_pay_for_goods
      summary_total_empty_deposit
      suggested_cart_total
      shipping_address {
        payer_name
        outlet_name
        street
        city
        country
        province
        postalcode
        sap_id
      }
      products {
        sku
        image
        name
        number_of_items_on_pallet
        number_of_items_on_layer
        total_units
        units
        number_of_layers
        number_of_pallets
        price_per_piece
        your_price_wo_vat
        your_price_w_vat
      }
    }
  }
}
`)
